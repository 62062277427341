<template>
  <div class="text-left">
    <h3 class="f-bold cl-primary mb-4">My Profile</h3>
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Full Name</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.name }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Email</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.email }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Phone</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.telephone }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Gender</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.gender }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Username</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.username }}</div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-3">
        <h6 class="mb-0 cl-secondary">Address</h6>
      </div>
      <div class="col-sm-9 text-secondary">{{ userLogin.address }}</div>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary px-4" @click.prevent="toEditProfile()">
        Edit Profile
      </button>
      <button class="btn btn-warning px-4 btn-rounded" @click.prevent="toEditPassword()">
        Edit Password
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyProfile",
  computed: {
    userLogin: function () {
      return this.$store.getters.getProfileCustomers;
    },
  },
  mounted() {
    this.getProfileUser();
    this.scrollToTop();
  },
  methods: {
    getProfileUser() {
      this.$store.dispatch("getProfileCustomers");
    },

    toEditProfile() {
      this.$router.push("/customers/update-profile");
    },
   
    toEditPassword() {
      this.$router.push("/customers/update-password");
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
